<template>
  <div class="whitelist-page">
    <vuestic-widget >
      <template slot="header">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <span class="title">Whitelist</span>
          <div class="d-flex justify-content-end align-items-center">
            <button type="button" class="btn btn-primary btn-sm" @click="openModal">Add New <i class="fa fa-plus"></i></button>
          </div>
        </div>
      </template>
      <div>
      <div class="row mt-4">
        <div class="col-md-12">
          <div class="pb-2 mb-3">
            <div class="d-flex flex-row justify-content-end align-items-center right-filter-wrapper">
              <div class="d-flex align-items-center justify-content-end flex-row mr-3">
                <search-input
                  name="searchInput"
                  v-model="searchFilter"
                  @input="debounceSearch()"
                />
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center" v-if="isLoading">
            <span class="atom-spinner-wrapper">
              <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)"/>
            </span>
          </div>
          <div class="col-md-12" v-else>
            <datatable v-bind="ipTableOptions" class="le-datatable"/>
          </div>
        </div>
      </div>
      </div>
    </vuestic-widget>
    <vuestic-modal
      :isOpen="isOpenModal"
      @cancel="closeModal"
      :cancelShown="false"
      :okShown="false"
    >
      <span slot="title">Add new whitelist</span>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(handleAddWhitelist)">
          <div class="row">
            <div class="col-md-12">
              <text-input name="ip" v-model="newWhitelist.ip" label="IP"  />
            </div>
            <div class="col-md-12">
              <phone-number-input v-model="newWhitelist.phone" name="phone" :onlyCountries="[]" :disabledFetchingCountry="false" label="Phone"
                :required="false"/>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button type="button" class="btn btn-danger mr-2" @click="closeModal">Cancel</button>
              <button class="btn btn-primary" :disabled="isLoadingSave || (!newWhitelist.ip && !newWhitelist.phone)" style="min-width: 80px;">
                <atom-spinner v-if="isLoadingSave" slot="loading" :animation-duration="1500" :size="14" color="#FFF" style="margin: auto;"/>
                <span v-else>Save</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
    <vuestic-modal 
      :isOpen="isOpenModalDelete"
      :hideDefaultActions="true"
      @cancel="closeModalDelete"
      okText="Delete"
      :cancelShown="false"
      @ok="handleRemoveWhitelist"
      okClass="btn btn-danger btn-primary"
    >
      <span slot="title" class="text-danger">Delete Whitelist</span>
      <p v-if="selectedWhitelist && selectedWhitelist.ip">Are you sure you want to delete <strong>"<span >{{ selectedWhitelist && selectedWhitelist.ip }}</span>"</strong>?</p>
      <p v-else>Are you sure you want to delete <strong>"<span >{{ selectedWhitelist && selectedWhitelist.phone | phone }}</span>"</strong>?</p>
    </vuestic-modal>
  </div>
</template>
<script>
import Action from './Action'
import components from '../../common/tables/comps'
import debounce from "debounce"
import SearchInput from '../../common/SearchInput'

export default {
  components: {
    SearchInput
  },
  data () {
    return {
      isLoading: false,
      isOpenModal: false,
      isLoadingSave: false,
      isOpenModalDelete: false,
      selectedWhitelist: null,
      ipTableOptions: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          return [
            {
              title: "Ip",
              field: "ip",
              label: "Ip",
              tdClass: "center",
              sortable: true,
              visible: true
            },
            {
              title: "Phone",
              field: "phone",
              label: "Phone",
              tdClass: "center",
              tdComp: "TdPhone",
              sortable: true,
              visible: true
            },
            {
              title: '',
              tdComp: Action,
              visible: 'true',
              tdClass: 'center',
            },
          ]
        })(),
        data: [],
        total: 0,
        summary: {},
        query: {
          type: 'ip'
        },
        xprops: {
          eventbus: new Vue()
        }
      },
      newWhitelist: {},
      searchFilter: null,
    }
  },

  watch: {
    'ipTableOptions.query': {
      handler() {
        this.loadIps()
      },
      deep: true
    },
  },

  mounted () {
    this.ipTableOptions.xprops.eventbus.$on('openDeleteModal', row => {
      this.isOpenModalDelete = true
      this.selectedWhitelist = row
    })
  },

  methods: {
    closeModalDelete () {
      this.isOpenModalDelete = false
    },

    openModal () {
      this.isOpenModal = true
    },
    
    closeModal () {
      this.isOpenModal = false
    },

    loadIps () {
      this.isLoading = true
      this.ipTableOptions.query.search = this.searchFilter


      this.$store.dispatch('whitelist/all', this.ipTableOptions.query)
        .then((res) => {
          this.ipTableOptions.data = res.data
          this.ipTableOptions.total = res.meta.total
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
        })
    },

    debounceSearch: debounce(function() {
      this.loadIps()
    }, 500),

    handleAddWhitelist () {
      this.isLoadingSave = true
      this.$store.dispatch('whitelist/create', this.newWhitelist)
        .then(() => {
          this.newWhitelist = {}
          this.closeModal()
          this.loadIps()
          this.isLoadingSave = false
        })
        .catch(() => {
          this.isLoadingSave = false
        })
    },

    handleRemoveWhitelist () {
      this.$store.dispatch('whitelist/delete', this.selectedWhitelist.id)
        .then(() => {
          this.closeModalDelete()
          this.loadIps()
          this.isLoadingSave = false
        })
        .catch(() => {
          this.isLoadingSave = false
        })
    },
  },
}
</script>
<style lang="scss">
  .whitelist-page {
    .widget-body {
      padding: 0;
    }
    .nav-item {
      min-width: 120px;
      text-align: center;
    }
  }
</style>
